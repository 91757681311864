import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { InventoryPrintComponent } from "../pages";
import "../assets/styles/global.style.scss";
import { PosterComponent } from "../pages/poster/poster.component";
import { CataloguePrintComponent } from "../pages/catalogue-print";
import { ProductCataloguePrintComponent } from "../pages/product-catalogue-print";
import { LabelComponent } from "../pages/label/label.component";

export const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/inventory/:categoryId"
          element={<InventoryPrintComponent />}
        />
        <Route
          path="/catalogue/:matchingId"
          element={<CataloguePrintComponent />}
        />
        <Route
          path="/product-catalogue/:productId"
          element={<ProductCataloguePrintComponent />}
        />
        <Route path="/poster/:productId" element={<PosterComponent />} />
        <Route path="/label" element={<LabelComponent />} />
      </Routes>
    </Router>
  );
};
