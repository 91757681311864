import React from "react";
import { useSearchParams } from "react-router-dom";
import "./label.style.scss";

const logoUrl =
  "https://panel.armancnc.com/wp-content/uploads/2024/05/arman-final-black.png";

export const LabelComponent = () => {
  const [search] = useSearchParams();
  const data = {
    shipping_number: search.get("shipping_number"),
    coli_name: search.get("coli_name"),
    coli_number: search.get("coli_number"),
    coli_weight: search.get("coli_weight"),
    product_sku: search.get("product_sku"),
    product_name: search.get("product_name"),
    coli_qr: search.get("coli_qr"),
  };
  console.log(data);

  return (
    <div className="label-container --prevent-font" id="label-container">
      <div className="section section-one">
        <div className="flex-section">
          <div className="other-info-container">
            <img src={logoUrl} alt="" className="brand-logo" />
            <h3 className="brand-title">آرمان صنعت زنده رود</h3>
            <h2 className="arman-sku">{data.product_sku}</h2>
          </div>
          <div className="qr-code-container">
            <img src={data.coli_qr} alt="" />
          </div>
        </div>
        <div className="title-container">
          <h4>{data.product_name}</h4>
        </div>
      </div>
      <div className="section section-two">
        <h2 className="coli-name">نام نگله: {data.coli_name}</h2>
        <h3 className="coli-id">شناسه نگله: {data.coli_number}</h3>
        <h3 className="coli-weight">وزن نگله: {data.coli_weight}</h3>
      </div>
      <div className="section section-three">
        <h3 className="shipping-number-id">
          شماره بارنامه: {data.shipping_number}
        </h3>
      </div>
    </div>
  );
};
